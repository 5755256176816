export const GrocerIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg height='20' width='20' viewBox='0 0 48 48' {...props}>
    <g>
      <g>
        <path d='M45.8,16.4v-.3L40.8,5.2A2,2,0,0,0,39,4H9A2,2,0,0,0,7.2,5.2L2.3,16.1v.3a6,6,0,0,0,1,5.2,6.9,6.9,0,0,0,2.8,2V41a2.9,2.9,0,0,0,3,3H39a2.9,2.9,0,0,0,3-3V23.6a6.9,6.9,0,0,0,2.8-2A6,6,0,0,0,45.8,16.4ZM6,17.6,10.3,8H37.7L42,17.6a1.9,1.9,0,0,1-.4,1.5,2.1,2.1,0,0,1-1.8.9h-.9a2.2,2.2,0,0,1-2.3-2.1,2,2,0,0,0-4,0A2.1,2.1,0,0,1,30.4,20H28.2A2.1,2.1,0,0,1,26,17.9a2,2,0,0,0-4,0A2.1,2.1,0,0,1,19.8,20H17.6a2.1,2.1,0,0,1-2.2-2.1,2,2,0,0,0-4,0A2.2,2.2,0,0,1,9.1,20H8.2a2.1,2.1,0,0,1-1.8-.9A1.9,1.9,0,0,1,6,17.6ZM35,40V27H28V40H10V23.9a5.9,5.9,0,0,0,3.4-1.5A6.3,6.3,0,0,0,17.6,24h2.2A6.3,6.3,0,0,0,24,22.4,6.3,6.3,0,0,0,28.2,24h2.2a6.3,6.3,0,0,0,4.2-1.6A5.9,5.9,0,0,0,38,23.9V40Z' />
        <rect x='13' y='27' width='11' height='10' />
      </g>
    </g>
  </svg>
);
